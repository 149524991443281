import React from "react"
import LeftSideComponent from '../LeftSideComponent/LeftSideComponent'
import RightSideComponent from "../RightSideComponent/RightSideComponent"
import * as styles from './login.module.scss'
import LoginComponent from "./LoginComponent/LoginComponent"

export default _ => {
  return (
    <div className={styles.container}>
      <LeftSideComponent>
        <LoginComponent />
      </LeftSideComponent>
      <RightSideComponent />
    </div>
  )
}